<template>
  <div>
    <div
      :id="floaterId"
      ref="buttonRef"
      class="trigger"
    >
      <slot name="triggerContent">
        <div
          :style="{ backgroundImage: `url('${image}')` }"
          class="h-full w-full rounded-lg aspect-video bg-center bg-no-repeat bg-cover"
        ></div>
      </slot>
    </div>
    <Teleport
      to="body"
      :disabled="isXs"
    >
      <div
        :id="`${floaterId}__tooltip`"
        role="tooltip"
        class="tooltip aspect-video rounded-lg bg-brand-blue-900"
      >
        <FloaterTooltipHero
          :id="`${floaterId}__tooltip__thumbnail`"
          :is-fully-expanded="isFullyExpanded"
          :floater-image="floaterImage"
          :transition-state="transitionState"
          :media="media"
          @on-stop-trailer="onOnStopTrailer"
        >
        </FloaterTooltipHero>
        <div
          v-if="isFullyExpanded"
          :id="`${floaterId}__tooltip__content`"
        >
          <FloaterTooltipInfo
            :media="media"
            @on-dismiss="(action) => hideTooltip(true, action)"
          ></FloaterTooltipInfo>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script setup>
// TODO: when window stops scrolling, re-anchor
import { useInterval, useElementBounding, useWindowScroll } from "@vueuse/core";
import { computePosition, offset } from "@floating-ui/dom";
import { PLAYERS } from "@/constants";

const props = defineProps({
  floaterId: {
    type: String,
    required: true,
  },
  image: {
    type: String,
    required: true,
  },
  floaterImage: {
    type: String,
  },
  media: {
    type: Object,
    required: true,
  },
  isBeingDragged: {
    type: Boolean,
    default: () => false,
  },
  disabled: {
    type: Boolean,
    default: () => false,
  },
});

const timerRef = ref(null);
const buttonRef = ref();
const buttonBounding = useElementBounding(buttonRef);
const isFullyExpanded = ref(false);

const transitionState = ref("default");
const trailerTimeout = ref();
const timer = ref();
const animationTimer = ref();

const allPlayers = useAllPlayers();

// scroll watcher
const { y } = useWindowScroll();
const { isXs } = useBreakpoints();

const showTooltip = async () => {
  const tooltipEl = document.querySelector(`#${props.floaterId}__tooltip`);
  await nextTick();
  tooltipEl.style.display = "block";
  anchorTooltip();
  buttonBounding.update();

  const newWidth = 381;
  const newHeight = 434;
  const sideWidth = (newWidth - buttonBounding.width.value) / 2;
  const sideHeight = (newHeight - buttonBounding.height.value) / 2;

  // Calculate the translation values
  const translateX = -sideWidth;
  const translateY = -sideHeight;

  // Add the animated class to trigger the animation
  tooltipEl.classList.add("animated");

  // Set width and height directly
  tooltipEl.style.width = `${newWidth}px`;
  tooltipEl.style.height = `${newHeight}px`;

  // // Apply the translation
  tooltipEl.style.transform = `translate(${translateX}px, ${translateY}px)`;

  stopTimer();

  tooltipEl.style.zIndex = 50;
  timer.value = setTimeout(() => {
    timer.value && clearTimeout(timer.value);
    isFullyExpanded.value = true;
  }, 100);

  // pause all video elements
  allPlayers.pauseAll();

  if (hasTrailer.value) {
    trailerTimeout.value = setTimeout(() => {
      trailerTimeout.value && clearTimeout(trailerTimeout.value);
      transitionState.value = "player";
    }, 2500);
  }
};

const hideTooltip = (animate, action) => {
  isFullyExpanded.value = false;
  const tooltipEl = document.querySelector(`#${props.floaterId}__tooltip`);

  if (animate) {
    // Add the animated class to trigger the exit animation
    tooltipEl.classList.add("animated");
  }

  // Set width and height directly
  tooltipEl.style.width = `${buttonBounding.width.value}px`;
  tooltipEl.style.height = `${buttonBounding.height.value}px`;
  tooltipEl.style.transform = `translate(0, 0)`;

  clearTimeout(trailerTimeout.value);

  // Remove the tooltip when the exit animation is complete
  animationTimer.value = setTimeout(() => {
    animationTimer.value && clearTimeout(animationTimer.value);
    tooltipEl.classList.remove("animated");
    tooltipEl.style.zIndex = 0;
    tooltipEl.style.display = "none";
    transitionState.value = "default";

    if (action == "showDetail") {
      useInfoClickEvent(
        {
          ...props.media,
          contentType: props.media?.contentType || "Movie",
        },
        { replace: true }
      );
    }
  }, 200); // Adjust the duration as needed
};

const startTimer = () => {
  if (isFullyExpanded.value || props.isBeingDragged || isXs.value) return;
  timerRef.value = useInterval(10, { controls: true });
};

const stopTimer = () => {
  timerRef.value?.reset();
  timerRef.value?.pause();
};

const dismissTooltip = async () => {
  stopTimer();
  hideTooltip(true);
  allPlayers.resume(PLAYERS.HERO_PLAYER);

  const floaterPlayer = allPlayers.getStore(PLAYERS.FLOATER_PLAYER);
  await floaterPlayer?.player?.destroy();
  floaterPlayer?.$reset();
};

watch(
  () => timerRef.value?.counter,
  (newValue) => {
    if (newValue >= 80) {
      timerRef.value.pause();
      showTooltip();
    }
  }
);

/**
 * watch this prop value. if being dragged, stop timer
 * (timer has been activated on that miniscule moment before dragging)
 * when value becomes false, re-anchor tooltip
 */
watch(
  () => props.isBeingDragged,
  (newValue) => {
    if (newValue) {
      stopTimer();
      return;
    }

    anchorTooltip();
  }
);

const anchorTooltip = () => {
  const buttonEl = document.querySelector(`#${props.floaterId}`);
  const tooltipEl = document.querySelector(`#${props.floaterId}__tooltip`);

  // anchor tooltip component to parent
  computePosition(buttonEl, tooltipEl, {
    middleware: [
      // Assumes placement is 'bottom' (the default)
      offset(({ rects }) => {
        return -rects.reference.height / 2 - rects.floating.height / 2;
      }),
    ],
  }).then(({ x, y }) => {
    Object.assign(tooltipEl.style, {
      left: `${x}px`,
      top: `${y}px`,
    });
  });
};

/**
 * define which events to trigger which function on which element
 */
const registerEvents = () => {
  if (props.disabled) return;
  const buttonEl = document.querySelector(`#${props.floaterId}`);
  const tooltipEl = document.querySelector(`#${props.floaterId}__tooltip`);

  [
    ["mouseenter", startTimer],
    ["mouseleave", stopTimer],
    // any other events
  ].forEach(([event, listener]) => {
    buttonEl.addEventListener(event, listener);
  });

  [
    ["mouseleave", dismissTooltip],
    // any other events
  ].forEach(([event, listener]) => {
    tooltipEl.addEventListener(event, listener);
  });
};

onBeforeUnmount(() => {
  timer.value && clearTimeout(timer.value);
  stopTimer();
  timeout.value && clearTimeout(timeout.value);
  animationTimer.value && clearTimeout(animationTimer.value);
  trailerTimeout.value && clearTimeout(trailerTimeout.value);
});

onMounted(async () => {
  await nextTick();
  buttonBounding.update();
  // tooltipEl.style.display = "block";

  anchorTooltip(false);
  hideTooltip();
  registerEvents();
  watch(y, handleScroll);
});

/**
 * watch for scrolling in the window
 */
const isScrolling = ref(false);
const timeout = ref(null);

// Function to handle scrolling
const handleScroll = () => {
  // Clear the previous timeout
  clearTimeout(timeout.value);

  // Set a new timeout to detect when scrolling stops
  timeout.value = setTimeout(() => {
    timeout.value && clearTimeout(timeout.value);
    isScrolling.value = false;
    // Perform your action when scrolling stops
    onStopScrolling();
  }, 200); // Adjust the timeout duration as needed
};

// Function to execute when scrolling stops
const onStopScrolling = async () => {
  // Do something when scrolling stops
  await nextTick();
  // await showTooltip(true);
  anchorTooltip();
  buttonBounding.update();

  // hideTooltip();
};

const onOnStopTrailer = () => {
  transitionState.value = "default";
};

const hasTrailer = computed(() => {
  return Object.keys(props.media).includes("attachmentPlayingInfo");
});
</script>

<script>
export default { name: "FloaterPopup" };
</script>

<style lang="scss" scoped>
.tooltip {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: center center;

  &.animated {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
  }
}
</style>
