<template>
  <div class="aspect-video">
    <Transition name="fade" mode="out-in" @after-leave="onAfterLeave">
      <!-- :style="{ backgroundImage: `url('${floaterImage}')` }" -->
      <div
        v-if="transitionState == 'default'"
        :style="safeFloaterImage"
        :class="[isFullyExpanded ? 'rounded-t-lg' : 'rounded-lg']"
        class="aspect-video bg-center bg-no-repeat bg-cover"
      ></div>
      <div v-else class="relative w-full rounded-t-lg aspect-video">
        <div
          class="absolute top-0 start-0 w-full h-full gradient vertical"
        ></div>
        <div
          class="absolute z-10 top-0 start-0 w-full h-full p-4 flex gap-x-2 items-end justify-end"
        >
          <ButtonsIcon
            class="variant-secondary !w-9 !h-9"
            icon-name="IconTimesWhite"
            @click.stop="onStopTrailer"
            size="20"
          ></ButtonsIcon>
          <ButtonsIcon
            size="20"
            :icon-name="volumeIconName"
            class="variant-secondary !w-9 !h-9"
            @click.stop="store.toggleMute()"
          ></ButtonsIcon>
        </div>
        <div class="relative w-full h-full">
          <video
            id="trailerEl"
            autoplay
            playsinline
            class="rounded-t-lg w-full h-full"
          ></video>
          <div
            :style="{ width: progress }"
            class="absolute bottom-0 h-[6px] bg-brand-red transition-all ease-linear left-0 rounded-bl-lg"
          ></div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { PLAYERS } from "@/constants";
import { usePlayerStoreWithID } from "@/stores/playerWithId";

const props = defineProps({
  media: Object,
  floaterImage: String,
  isFullyExpanded: Boolean,
  transitionState: String,
});

const emit = defineEmits("onStopTrailer");

const hideTimeout = ref();
const showHoveredContent = ref();

const progress = ref("0%");

const store = usePlayerStoreWithID(PLAYERS.FLOATER_PLAYER)();

const safeFloaterImage = computed(() => {
  if (props.floaterImage) {
    return { backgroundImage: `url('${props.floaterImage}')` };
  }

  return useFallbackImage().style;
});

const onAfterLeave = async () => {
  if (props.transitionState == "default") {
    await store.player.destroy();
    return;
  }

  await loadPlayer();
};

async function loadPlayer() {
  store.initialize("trailerEl");
  await store.loadMedia(
    {
      url: props.media.attachmentPlayingInfo.url,
    },
    {},
    {},
    {
      sesContentType: "Trailer",
      sesSessionType: "Trailer",
    }
  );

  registerEvents();

  hideUi();
}

const registerEvents = () => {
  watch(
    () => store.playbackState,
    (newVal) => {
      if (newVal == "ended") {
        onStopTrailer();
      }
    }
  );

  watch(
    () => store.currentTime,
    () => {
      const perc = Math.min(store.indicatorPosition * 100, 100);
      progress.value = `${perc}%`;
    }
  );
};

function hideUi() {
  clearTimeout(hideTimeout.value); // Clear any existing timeout.
  hideTimeout.value = setTimeout(() => {
    hideTimeout.value && clearTimeout(hideTimeout.value);
    showHoveredContent.value = false;
  }, 3000);
}

const volumeIconName = computed(() =>
  store.isMuted ? "IconVolumeOff" : "IconVolumeHigh"
);

const onStopTrailer = async () => {
  await store.player.reset();
  await store.player.destroy();

  emit("onStopTrailer");
};

onBeforeUnmount(() => {
  hideTimeout.value && clearTimeout(hideTimeout.value);
});
</script>

<script>
export default { name: "FloaterHero" };
</script>

<style lang="scss" scoped></style>
