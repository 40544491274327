<template>
  <div v-if="list && (list.size || list.length) > 0">
    <client-only>
      <template #fallback></template>
      <CarouselSection
        :hide-view-all-btn="list && (list.size || list.length) < 20"
        :items="parsedList"
        :title-options="titleOptions"
        :carousel-id="carouselId"
        :carousel-options="carouselOptions"
        :apiOptions="apiOptions"
        :carouselType="carouselType"
        @is-dragging="(e) => (carouselDisabled = e)"
      >
        <template #title>
          <slot
            v-if="list && (list.size || list.length) > 0"
            name="title"
          ></slot>
        </template>
        <template #slider-item="{ item, key }">
          <slot name="slider-item" :item="item" :key="key">
            <Floater
              :key="`${carouselId}-${key}`"
              :floater-id="useKebabCase(`${carouselId}-incarousel-${key}`)"
              :image="item.imageUrl"
              :floater-image="imageAssets(item)"
              :disabled="disableFloater(item)"
              :media="item"
              :is-being-dragged="carouselDisabled"
              :class="[
                carouselType,
                { 'font-bold': $route.path.includes('kids') },
              ]"
              class="thumbnail"
              @click="onFloaterClick(item)"
            >
              <template #triggerContent>
                <CarouselType
                  :type="carouselType"
                  :item="item"
                  :carousel-index="key"
                ></CarouselType>
              </template>
            </Floater>
          </slot>
        </template>
      </CarouselSection>
    </client-only>
  </div>
</template>

<script setup>
const { asDurationPretty } = useDateTime();

const {
  titleOptions,
  apiOptions,
  thumbnailOptions,
  carouselType,
  floaterOptions,
} = defineProps({
  /**
   * label: 'Recommended for you',
   * path: '/recommended',
   */
  titleOptions: {
    type: Object,
    required: true,
  },
  /**
   * showTitle: true,
   * showProgress: true,
   * type: 'portrait', // null for landscape, other values: top_10, live
   * size: 'medium',
   */
  thumbnailOptions: {
    type: Object,
    required: true,
  },
  /**
   * type: Object,
   * query: Object,
   */
  apiOptions: {
    type: Object,
    required: true,
  },
  carouselId: {
    type: String,
    required: true,
  },
  carouselOptions: {
    type: Object,
    default: () => null,
  },
  carouselType: {
    type: String,
  },
  /**
   * disabled: true/false,
   * onClick: func
   */
  floaterOptions: Object,
});

const imageAssets = computed(() => (item) => {
  const image = useMediaAssets(item);
  return image?.Banner || image?.horizontalimage || image?.pillarimage;
});
const localePath = useLocalePath();
const carouselDisabled = ref(false);

let count = 20;
if (carouselType == "top_ten") {
  count = 10;
}

const options = {
  from: 0,
  count,
  ...apiOptions.query,
};

const list = ref();
list.value = await useGetMethod(`/api${apiOptions.path}`, options);
const orientation = computed(() => {
  switch (thumbnailOptions?.card_type) {
    case "portrait":
    case "top_10":
      return "VerticalImage";
    case "circle":
      return "logo";

    default:
      return "HorizontalImage";
  }
});

const isSport = (item) => item?.channelExternalId?.includes("ad_sports");
const disableFloater = (item) => {
  if (floaterOptions?.disabled) return true;

  const isOfItemType = ["Theme", "Browser", "Player", "Genre"].includes(
    item.type
  );
  const isOfCarouselType = ["big_subjects", "thumbnail_with_progress"].includes(
    carouselType
  );
  const isSportType = isSport(item);
  return isOfItemType || isOfCarouselType || isSportType;
};

const parsedList = computed(() => {
  let _list = [];
  if (!list.value) return [];

  if (Array.isArray(list.value)) {
    _list = list.value;
  } else {
    _list = list.value.contents || [];
  }

  return _list.map((l) => {
    const { startDate, endDate } = l;
    let durationPretty;
    if (startDate && endDate) {
      durationPretty = asDurationPretty((endDate - startDate) / 1000);
    }
    return {
      ...l,
      imageUrl: useThumbnailOrientation(l, { orientation: orientation.value }),
      images: useMediaAssets(l),
      durationPretty,
      playButtonConfig: useCtaItems(l),
    };
  });
});

const onFloaterClick = (item) => {
  if (floaterOptions?.onClick) {
    return floaterOptions.onClick(item);
  }

  return useFloaterClick(item, carouselType);
};
</script>

<script>
export default { name: "CarouselIndex" };
</script>

<style lang="scss" scoped></style>
