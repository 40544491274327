<template>
  <div class="p-4 flex flex-col gap-y-4">
    <div class="flex flex-col">
      <h2
        class="text-title-big-bold text-white opacity-[0.87] cursor-pointer"
        @click="onInfoClick"
      >
        {{ label }}
      </h2>

      <OverlayDescriptionTags :media="media"></OverlayDescriptionTags>

      <div class="p-0.5">
        <p
          class="line-clamp-3 text-title-small text-white opacity-[0.87] tracking-[0.2px]"
        >
          {{ media.description }}
        </p>
      </div>
    </div>
    <div class="flex justify-between items-center">
      <div class="flex gap-x-2">
        <OverlayCtaSection
          :media="media"
          :show-label="media.type != 'Movie'"
          :in-floater="true"
          id="floater__cta"
        ></OverlayCtaSection>
      </div>
      <div v-show="false" class="flex gap-x-2">
        <span
          class="rounded-sm border border-white/10 bg-white/5 px-2 py-1 font-Abu text-label-medium text-white opacity-[0.87]"
        >
          {{ $t("16+") }}
        </span>
        <span
          class="rounded-sm border border-white/10 bg-white/5 px-2 py-1 font-Abu text-label-medium text-white opacity-[0.87]"
        >
          {{ $t("HD") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useSessionStore } from "@/stores/session";
const sessionStore = useSessionStore();

const props = defineProps({
  media: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits("on-dismiss");

const label = computed(() => {
  if (props.media.type == "LiveProgram") {
    if (
      Object.keys(props.media).includes("extendedProgram") &&
      props.media.extendedProgram.name
    ) {
      return props.media.extendedProgram.name;
    }
  }

  return props.media.name;
});

const onInfoClick = () => {
  emit("on-dismiss", "showDetail");
};
</script>

<style lang="scss" scoped></style>
